import { Component, Prop, Host, h } from '@stencil/core';

/**
 * This will allow the text to change on the client side after a certain
 * date has passed without having to redeploy.
 */
@Component({
  tag: 'change-copy-on',
  shadow: true,
})
export class ChangeCopyOn {
  /**
   * Date formatted as YYYY-MM-DD
   */
  @Prop() on: string;

  @Prop() pre: string;

  @Prop() post: string;

  render() {
    const { on, pre, post } = this;
    const date = new Date(on).valueOf();

    const text = date > Date.now() ? pre : post;

    return <Host>{text}</Host>;
  }
}
