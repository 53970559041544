import { Component, Host, Prop, State, h, Fragment } from '@stencil/core';

import type { GridResponseProperties } from '../my-weather/weatherTypes';

/**
 * This is meant to present comprehensive weather data for a single day
 */
@Component({
  tag: 'daily-wrapper',
  styleUrl: 'daily-wrapper.css',
  shadow: false,
})
export class DailyWrapper {
  @Prop() forecastEndpoint: string;

  @State() forecast: GridResponseProperties;

  render() {
    const { forecastEndpoint, forecast } = this;

    const getTomorrow = () => {
      return forecast ? Object.keys(forecast.days)[1] : '';
    };

    if (!forecast) {
      fetch(`${forecastEndpoint}/daily`)
        .then((res: Response) => {
          if (res.ok) return res.json();
        })
        .then(body => {
          this.forecast = body;
        });
    }
    console.log(forecast);
    const tomorrow = getTomorrow();

    const sunriseDate = new Date(
      `${tomorrow} ${forecast?.days[tomorrow].sunrise}`,
    );
    const sunsetDate = new Date(
      `${tomorrow} ${forecast?.days[tomorrow].sunset}`,
    );

    return (
      <Host>
        {forecast && (
          <>
            <span>Hourly temperatures for {getTomorrow()}</span>
            <hourly-view
              periods={forecast.days[getTomorrow()].periods}
            ></hourly-view>

            <sunrise-sunset
              sunrise={sunriseDate}
              sunset={sunsetDate}
            ></sunrise-sunset>
          </>
        )}
      </Host>
    );
  }
}
