import { Component, Prop, Host, h } from '@stencil/core';

import { getSubscriberID } from '../../utils/utils';

/**
 * Designed to request notification permissions and tthen get a subscription.
 * The subscription will be sent to the `subscriptionEndpoint` with a unique ID
 * appended to the URL.
 */
@Component({
  tag: 'push-subscriber',
  styleUrl: 'push-subscriber.css',
  shadow: true,
})
export class PushSubscriber {
  check = () => {
    if (!('serviceWorker' in navigator)) {
      console.error('No Service Worker support!');
      return false;
    }

    if (!('PushManager' in window)) {
      console.error('No Push API Support!');
      return false;
    }
    return true;
  };

  requestNotificationPermission = async () => {
    const permission = await window.Notification.requestPermission();

    if (permission !== 'granted') {
      throw new Error('Permission not granted for Notification');
    }
  };

  @Prop() vapidPublicKey: string;

  /**
   * The URL to save the subscription at for future pushes
   */
  @Prop() subscriptionEndpoint: string;

  render() {
    const { check, requestNotificationPermission, vapidPublicKey, subscriptionEndpoint } = this;

    const canSubscribe = check();

    const subscribe = async () => {
      await requestNotificationPermission();

      const swRegistration = (await navigator.serviceWorker.getRegistrations())[0];

      await swRegistration.pushManager
        .getSubscription()
        .then(async subscription => {
          if (subscription) {
            return subscription;
          }

          const encoded = new TextEncoder().encode(vapidPublicKey);
          const decoded = new TextDecoder('utf-8').decode(encoded);

          return swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: decoded,
          });
        })
        .then(async subscription => {
          fetch(`${subscriptionEndpoint}/${getSubscriberID()}`, {
            method: 'PUT',
            body: JSON.stringify(subscription),
          });
        });

      console.log('Subscribed :)');
    };

    return <Host>{canSubscribe && <button onClick={subscribe}>Subscribe to notifications</button>}</Host>;
  }
}
