@import '~chartist/dist/index.css';

:host {
  display: block;
}

#chart {
  height: 80vh;
}

.ct-bar {
  /* @apply stroke-white; */
  stroke: white;
  stroke-width: 2px;
}

.ct-label {
  color: white;
}

rect {
  fill: white;
}
