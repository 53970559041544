import { Component, Prop, State, Host, h } from '@stencil/core';
import { getSubscriberID } from '../../utils/utils';

import type { GridResponseProperties, PeriodForecast } from './weatherTypes';

@Component({
  tag: 'my-weather',
  styleUrl: 'my-weather.css',
  shadow: false,
  scoped: false,
})
export class MyWeather {
  @State()
  forecast: GridResponseProperties;

  /**
   * Where to get weather data for the subscriber
   */
  @Prop() forecastEndpoint: string;

  render() {
    const { forecastEndpoint, forecast } = this;
    const { city, state } = this.forecast || {};

    if (!forecast) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords;
        fetch(
          `${forecastEndpoint}/forecast/${getSubscriberID()}/${latitude},${longitude}`,
        )
          .then(res => {
            if (res.ok) return res.json();
          })
          .then((body: GridResponseProperties) => {
            console.log(body);
            this.forecast = body;
          });
      });
    }

    // TODO: Improve this and add error handling
    const forecastToCondition = (shortForecast: string): string => {
      console.log('SHORT FORECAST', shortForecast);
      return shortForecast
        .toLowerCase()
        .match(/(cloudy|rain|sunny|clear|thunderstorms)/)[0];
    };

    const createDayCode = (periods: PeriodForecast[]) => {
      let prevCondition = periods[0].forecast;
      let lastEnd = -1;
      let hour = 1;
      const segments = periods.reduce((acc, cur) => {
        // console.log('CUR', cur);
        if (cur.forecast !== prevCondition || hour === 24) {
          const startHour = new Date(cur.start).getHours();
          acc.push(
            `${lastEnd + 1}-${hour === 24 ? startHour + 1 : startHour}:${forecastToCondition(prevCondition)}`,
          );

          lastEnd = startHour - 1;
          prevCondition = cur.forecast;
        }
        hour++;

        return acc;
      }, []);

      return segments.join('|');
    };

    return (
      <Host>
        {!forecast && <div>Waiting to get weather data</div>}

        {forecast && (
          <div>
            Here is a short summary of the weather in&nbsp;
            <span class="font-bold">
              {city}, {state}
            </span>
            <br />
            <ol>
              {Object.entries(forecast.days).map(([day, dailyForecast]) => {
                console.log('DAY', day);
                const dayCode = createDayCode(dailyForecast.periods);
                console.log(dayCode);
                return (
                  <li key={day}>
                    {day}
                    <day-bar dayCode={dayCode}></day-bar>
                  </li>
                );
              })}
            </ol>
          </div>
        )}
      </Host>
    );
  }
}
