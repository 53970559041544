import { Component, Event, EventEmitter, Prop, Host, h } from '@stencil/core';

/**
 * This component exists so that we can more easily separate code from configuration.
 * The way we were registering the service worker meant that we would have to hard-code
 * the path to an esbuild-compiled asset. This way we can put it into the HTML at build
 * time and let our JS access it in a more future-proof way.
 *
 * This should only be loaded at the root of your site/app
 */
@Component({
  tag: 'register-service-worker',
  shadow: true,
})
export class RegisterServiceWorker {
  @Prop() path: string;

  /**
   * We fire an event so that other JS can get the ServiceWorkerRegistration
   */
  @Event({
    eventName: 'workerRegistered',
    composed: true,
    cancelable: true,
    bubbles: true,
  })
  workerRegistered: EventEmitter;

  render() {
    const { path } = this;

    if (path) {
      navigator.serviceWorker.register(path, { scope: '/' }).then(
        registration => {
          console.log('Service worker registration succeeded:', registration);
          this.workerRegistered.emit(registration);
        },
        error => {
          console.error(`Service worker registration failed: ${error}`);
        },
      );
    }

    return <Host></Host>;
  }
}
