import { Component, Host, Prop, h } from '@stencil/core';
import { BarChart, Svg } from 'chartist';

import type { PeriodForecast } from '../my-weather/weatherTypes';

@Component({
  tag: 'hourly-view',
  styleUrl: 'hourly-view.css',
  // shadow: true,
  // scoped: true,
})
export class HourlyView {
  @Prop() periods: PeriodForecast[];

  componentDidRender() {
    const hours = new Array(12).fill(0).map((_v, i) => {
      return i === 0 ? 12 : i;
    });
    const am = hours.map(h => `${h}AM`);
    const pm = hours.map(h => `${h}PM`);

    const temps = this.periods.map(
      (period: PeriodForecast) => period.temperature,
    );

    const chart = new BarChart(
      '#chart',
      {
        labels: am.concat(pm),
        series: [temps],
      },
      {
        high: Math.max(...temps) + 4,
        low: Math.min(...temps) - 4,
        reverseData: true,
        horizontalBars: true,
        axisX: {
          showGrid: false,
          showLabel: false,
        },
        axisY: {
          showGrid: false,
          showLabel: true,
          // labelInterpolationFnc(value, index) {
          //   return index % 2 === 0 ? null : value;
          // },
          offset: 60,
          labelOffset: {
            x: 0,
            y: 0,
          },
        },
      },
      [
        [
          'screen and (min-width: 600px)',
          {
            width: '80%',
          },
        ],
      ],
    );

    chart.on('draw', data => {
      if (data.type === 'bar') {
        // Create a "pill" for the label to sit on
        data.group.append(
          new Svg('rect', {
            x: data.x2,
            y: data.y2 - 10,
            rx: 10,
            ry: 10,
            width: 40,
            height: 20,
          }),
        );

        data.group.append(
          new Svg('text', {
            'x': data.x2 + 10,
            'y': data.y2 + 5,
            'font-size': '1rem',
            // @ts-expect-error
          }).text(data.value.x),
        );
      }
    });
  }

  render() {
    return (
      <Host>
        <div id="chart"></div>
      </Host>
    );
  }
}
