import "$styles/index.css";
import "$styles/syntax-highlighting.css";

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}";

import { defineCustomElements } from "packages/component-library/loader";
defineCustomElements();

console.info("Bridgetown is loaded!");

import { PagefindUI } from "@pagefind/default-ui";
import _styles from "@pagefind/default-ui/css/ui.css";

window.addEventListener("DOMContentLoaded", (event) => {
  new PagefindUI({ element: "#pagefind-container" });
});
