import { Component, Host, Prop, h } from '@stencil/core';
import { PieChart, Svg } from 'chartist';

@Component({
  tag: 'sunrise-sunset',
  styleUrl: 'sunrise-sunset.css',
  // shadow: true,
})
export class SunriseSunset {
  @Prop() sunrise: Date;
  @Prop() sunset: Date;

  componentDidLoad() {
    const { sunrise, sunset } = this;
    const totalSunupTime = sunset.getTime() - sunrise.getTime();

    // TODO: Make this be current time when we can conditionally display the marker
    const noon = new Date(sunrise.toDateString());
    noon.setHours(12);

    const timePassedSinceSunrise = noon.getTime() - sunrise.getTime();

    const completionAngle = (timePassedSinceSunrise / totalSunupTime) * 180;

    let sunMarker = null;
    const chart = new PieChart(
      '#sun-chart',
      {
        series: [
          {
            value: completionAngle,
            name: 'past',
            className: 'stroke-amber-100',
          },
          {
            value: 180 - completionAngle,
            name: 'Future',
            className: 'stroke-neutral-500',
          },
        ],
      },
      {
        donut: true,
        donutWidth: 10,
        startAngle: 270,
        total: 360,
        showLabel: false,
        classNames: {
          chartDonut: 'overflow-visible',
        },
      },
    );

    let bottomAlignment = 0;
    // Listen for draw events on the bar chart
    chart.on('draw', data => {
      console.log(data);
      // If this draw event is of type bar we can use the data to create additional content
      if (data.type === 'slice') {
        if (data.index === 0) {
          sunMarker = new Svg(
            'circle',
            {
              cx: data.path.pathElements[0].x,
              cy: data.path.pathElements[0].y,
              r: 12,
            },
            'fill-amber-400',
          );
          bottomAlignment =
            // @ts-expect-error
            data.path.pathElements[1].y + data.path.pathElements[1].ry / 2;
          data.group.append(
            new Svg('text', {
              // @ts-expect-error
              x: data.path.pathElements[1].x - data.path.pathElements[1].rx / 2,
              y: bottomAlignment,
            }).text(sunrise.toLocaleTimeString()),
          );
        } else {
          data.group.append(
            new Svg('text', {
              // @ts-expect-error
              x: data.path.pathElements[0].x - data.path.pathElements[1].rx / 2,
              y: bottomAlignment,
            }).text(sunset.toLocaleTimeString()),
          );

          // NOTE: we do the appending here so that it will be drawn on top of everything else
          data.group.append(sunMarker);
        }
      }
    });
  }
  render() {
    return (
      <Host>
        <div id="sun-chart"></div>
        <div class="text-sm text-center">
          Powered by <a href="https://sunrisesunset.io/">SunriseSunset.io</a>
        </div>
      </Host>
    );
  }
}
