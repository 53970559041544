export function format(first: string, middle: string, last: string): string {
  return (first || '') + (middle ? ` ${middle}` : '') + (last ? ` ${last}` : '');
}

export function getSubscriberID() {
  const key = 'subscriberID';
  const stored = localStorage.getItem(key);

  if (stored) {
    return stored;
  } else {
    const id = crypto.randomUUID();
    localStorage.setItem(key, id);
    return id;
  }
}
